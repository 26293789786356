@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@500;700&family=Muli:wght@700&display=swap');

* {
  font-family: 'Mukta', sans-serif !important;
}

a {
  color: #363636 !important;
}

a:hover {
	color: #FFBF00 !important;
}

a.no-hover-change:hover {
  color: #FFFFFF !important;
}

a.underline-contact-number {
  text-decoration: underline;
}

.hero.is-primary {
  background-color: #FFBF00 !important;
  background-image: none;
}

.tag:not(body).is-primary {
	background-color: #FFBF00 !important;
}

.button.is-primary {
  background-color: #FFBF00 !important;
  font-weight: 700;
}

.button.is-primary.is-outlined {
	background-color: #FFFFFF !important;
	border: 1px solid #FFBF00 !important;
  color: #FFBF00 !important;
  font-weight: 700;
}

.button.is-primary.is-outlined:hover {
	background-color: #FFBF00 !important;
	border: 1px solid #FFBF00 !important;
	color: #FFFFFF !important;
}

.navbar-item img {
  max-height: 4.5rem !important;
}

.location {
  margin-right: 0.5em !important;
  margin-bottom: 0.25em !important;
}

.mt-1 {
  margin-top: 1em !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.mb-2 {
  margin-bottom: 2em !important;
}

.mt-2 {
  margin-top: 2em !important;
}

.mt-3 {
  margin-top: 3em !important;
}

.mt-5 {
  margin-top: 5em !important;
}

.mt-05 {
  margin-top: 0.5em !important;
}

.spacer {
  height: 100px !important;
}

textarea:focus, input:focus, input, .textarea, .input {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.custom-input {
  border: 1px solid #ced4da !important;
  padding: 1.5em !important;
}

.custom-textarea {
  border: 1px solid #ced4da !important;
  padding: 1.5em !important;
}

.is-valid {
  border: 1.5px solid hsl(171, 100%, 41%) !important;
}

.is-invalid {
  border: 1.5px solid hsl(348, 100%, 61%) !important;
}

.location-input-padding {
  padding-left: 3em !important;
}

.location-input-icon-padding {
  padding-left: 1.5em !important;
  padding-top: 0.65em !important;
}

@media only screen and (max-width: 768px) {
  .mobile-flex {
    display: flex !important;
    justify-content: space-around !important;
    flex-direction: column !important;
  }

  .footer-item {
    padding: 0.5em 0 !important;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-flex {
    display: flex !important;
  }

  .footer-item {
    padding: 0 1em !important;
  }
}

.rccs {
  margin: 1em 0 !important;
}

button:active, button:focus, button {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

span.has-text-primary {
  color: #FFBF00 !important;
}

.tabs.is-toggle li.is-active a {
  border-color: #FFBF00 !important;
  background-color: #FFBF00 !important;
}

.tabs.is-toggle li.is-active a:hover {
  color: #FFFFFF !important;
}

.tabs.is-toggle li a:hover {
  background-color: #FFFFFF;
  border-color: #dbdbdb !important;
}
/* React GeoSuggest */
/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest {
  position: relative;
  display: flex;
  text-align: left;
}

.geosuggest__input-wrapper {
  width: 100%;
}

.geosuggest__input {
  font-size: 15px !important;
}

.geosuggest__input::placeholder {
  color:hsl(0, 0%, 71%);
  font-size: 15px;
}

.geosuggest__suggests:before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  border-width: 0 8px 8px 8px;
  border-style: solid;
  border-color: rgba(0,0,0,0.1) transparent;
}

.geosuggest__suggests:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  bottom: 100%;
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: black transparent;
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: 3px;
  background: #fff;
  border: 1px solid #ced4da;
  border-top-width: 0;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 16px;
  font-size: 1rem;
  padding: .5em .65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: hsl(0, 0%, 21%);
  color: #fff;
}
.geosuggest__item--active {
  background: hsl(0, 0%, 21%);
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: hsl(0, 0%, 21%);
}
.geosuggest__item__matched-text {
  font-weight: bold;
}
