.mr-1 {
  margin-right: 1em;
}

.ml-1 {
  margin-left: 1em;
}

.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 400ms ease-in-out;
}

.ReactModal__Overlay--after-open{
	opacity: 1;
}

.ReactModal__Overlay--before-close{
	opacity: 0;
}

.circle {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}